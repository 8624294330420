<template>
<div class="fixed z-20 bg-white shadow notification-pop-up px-3 pb-5 grid grid-cols-2" :style="{ top: '56%', right: '72px', width: '300px' }" v-if="resultModel">
  <div class="col-start-1 col-end-3 font-SourceSansPro font-bold text-headerText mt-2 px-2">{{ $t("measure.title") }}</div>
  <div class="col-start-1 col-end-3 font-SourceSansPro mt-2 break-words">
    <p class="px-2 text-sm font-semibold">{{ $t("measure.description") }}</p>
    <div class="flex mt-6 ml-2">
      <skifRadioOld class="mr-4" value="line" :label="$t('measure.line')" name="measure" v-model="measureType"></skifRadioOld>
      <skifRadioOld value="polygon" :label="$t('measure.polygon')" name="measure" v-model="measureType"></skifRadioOld>
      <button class="mb-2 ml-4 focus_outline-none font-SourceSansPro font-bold text-darkblue text-base" @click="handlCancel">{{ $t('measure.cancel') }}</button>
    </div>
  </div>
  <div class="col-start-1 col-end-3 ml-2">
    <div class="mt-3 pt-3 flex border-t border-divider" v-if="resultModel.lengthDisplay">
      <p class="text-textmain font-semibold text-sm font-SourceSansPro">{{ $t("measure.distance") }}</p>
      <p class="text-someGrey text-xs font-semibold leading-5 ml-2">{{ resultModel.lengthDisplay }}</p>
    </div>
    <div class="mt-3 pt-3 flex border-t border-divider" v-if="resultModel.areaDisplay && !resultModel.isLine">
      <p class="text-textmain font-semibold text-sm font-SourceSansPro">{{ $t("measure.region") }}</p>
      <p class="text-someGrey text-xs font-semibold leading-5 ml-2">{{ resultModel.areaDisplay }}</p>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { numberFormat as impNumberFormat } from '@/mixins/leaflet-measure/utils'

export default {
  data() {
    return {
      isPopoverVisible: false,
      measureType: 'polygon'
    }
  },
  computed: {
    ...mapState('measure', {
      isMeasureStarted: (state) => state.isMeasureStarted,
      resultModel: (state) => state.resultModel,
      reset: (state) => state.reset
    })
  },
  watch: {
    measureType (val) {
      this.$store.commit('measure/HANDLE_MEASURETYPE', val)
    },
    reset() {
      this.measureType = 'polygon'
    }
  },
  methods: {
    numberFormat(number, decimals, decPoint, thousandsSep) {
      return impNumberFormat(number, decimals, decPoint, thousandsSep)
    },
    handlCancel() {
      this.$store.commit('measure/CANCEL_MEASURE')
    }
  }
}
</script>
<style lang="stylus" scoped>
.notification-pop-up {
  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    left: 50%;
    margin-right: -6px;
    transform: rotate(45deg) translateY(-6px);
    background: #fff;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
}
